import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Container = styled.div`
  background-color: black;
  min-height: 50vh;

  @media screen and (max-width: 900px) {
    min-height: 0;
  }
`

const Content = styled.div`
  color: white;
  padding: 6% 12%;
  margin: auto;
  max-width: 800px;

  @media screen and (max-width: 900px) { 
    padding: 12%;
  }
`

const BlogPostContainer = styled(Link)`
  background-color: white;
  border-radius: 12px;
  text-decoration: none;
  box-shadow: none;
  color: black;
  display: inline-block;
  margin: auto auto 64px auto;
  width: 100%;

  :nth-child(even){ 
    margin-left: 20%;
  }

  :nth-child(odd) { 
    margin-left: -20%;
  }

  :last-child { 
    margin-bottom: 0;
  }

  h2 {
    color: black;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 36px;
    padding: 24px 36px;
  }

  p {
    font-family: "Roboto", sans-serif;

    :nth-child(2) {
      padding: 0 36px;
      font-size: 24px;
      font-weight: 300;
    }

    :nth-child(3) {
      text-align: right;
      font-size: 20px;
      font-weight: 300;
      padding: 24px 36px 24px 0;
      margin: 0;
    }
  }

  @media screen and (max-width: 1200px) { 
    :nth-child(even){ 
      margin-left: auto;
    }
  
    :nth-child(odd) { 
      margin-left: auto;
    }
  }

  @media screen and (max-width: 900px) {
    margin: 24px auto;
    width: 100%;

    :last-child { 
      margin-bottom: 24px;
    }
  

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 18px !important;
    }
  }
`

const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 36px 0;

  p {
    padding: 0 36px;
    font-size: 18px !important;
    font-weight: 300;
  }

  @media screen and (max-width: 900px) { 
    flex-direction: column;
    align-items: end;

    p { 
      font-size: 16px !important;
      margin: 0;
    }
  }
`

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout
      location={location}
      title={siteTitle}
      heroTitle="The Clink.Social Blog"
    >
      <SEO title="Blog" />
      <Container>
        <Content>
          {posts.map(({ node, index }) => {
            const title = node.frontmatter.title || node.fields.slug
            const link = node.fields.slug
            const date = node.frontmatter.date || ""
            const author = node.frontmatter.author || ""
            const description = node.frontmatter.description || node.excerpt
            return (
              <BlogPostContainer to={link}>
                <h2>{title}</h2>
                <p>{description}</p>
                <BottomRow>
                  <p>{author}</p>
                  <p>{date}</p>
                </BottomRow>
              </BlogPostContainer>
            )
          })}
        </Content>
      </Container>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            author
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
